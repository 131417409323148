import React, { Component, Suspense } from 'react';
import './Header.css';
import { Link, Route, Switch } from 'react-router-dom';
import { addClassInBody, removeClassFromBody } from "../../../utils/UseBodyClass";
import PrivacyPolicy from '../../routes/privacy-policy/PrivacyPolicy';
const Home = React.lazy(() => import('../../routes/home/Home'));
const Contact = React.lazy(() => import('../../routes/contact/Contact'));
const Brands = React.lazy(() => import('../../routes/brands/Brands'));
const Media = React.lazy(() => import('../../routes/media/Media'));
const PressRelease = React.lazy(() => import('../../routes/media/PressRelease'));
const About = React.lazy(() => import('../../routes/about/About'));
const Healthkart = React.lazy(() => import('../../routes/health-kart/Healthkart'));
const NotFound = React.lazy(() => import('../../static/404/NotFound'));
const ManufacturingUnit = React.lazy(() => import('../../routes/manufacturing-unit/ManufacturingUnit'));
class Header extends Component {

    constructor() {
        super();
        this.state = {
            leftNav: {
                open: false
            }
        }
    }

    openNav = () => {
        this.setState((previousState) => {
            return {
                leftNav: {
                    open: true
                }
            };
        }, () => {
            addClassInBody(`body_hidden`);
        });
    };

    closeNav = () => {
        this.setState((previousState) => {
            return {
                leftNav: {
                    open: false
                }
            };
        }, () => {
            removeClassFromBody(`body_hidden`);


        });

    };

    leftNavHandlerBtn = () => {
        console.log(this);
        if (this.state.leftNav.open) {
            return (<div className="cross" id="cross" onClick={this.closeNav}>x</div>)
        } else {
            return (<a onClick={this.openNav} className="openmenu">
                <img src={"assets/icons/menu.png"} alt="" />
            </a>)
        }
    };

    render() {
        let leftNavHandlerBtn = null;
        let openDrawerClasses;
        let backShadeClasses;
        if (this.state.leftNav.open) {
            leftNavHandlerBtn = (<div className="sideNavActionBtn sideNavClose" onClick={this.closeNav}>
                <img src={"/assets/icons/closeNavBtn.png"} alt="" />
            </div>);
            openDrawerClasses = ['sidenav', 'sideDrawer-open'];
            backShadeClasses = ['left-nav-overlay', 'backShade-open'];

        } else {
            leftNavHandlerBtn = (<a onClick={this.openNav} className="sideNavActionBtn">
                <img src={"/assets/icons/menu.png"} alt="" />
            </a>);
            openDrawerClasses = ['sidenav', 'sideDrawer-close'];
            backShadeClasses = ['left-nav-overlay', 'backShade-close'];


        }
        return (
            <React.Fragment>
                <div id="mySidenav" className={openDrawerClasses.join(" ")}>
                    <Link className="navbar_brand" to={"/"} onClick={this.closeNav}>
                        <img src={"/assets/images/logo_svg.svg"} alt="" id="brand" />
                    </Link>
                    {/*<a href="#" className="closebtn" onClick={this.closeNav}>&times;</a>*/}
                    <Link to="/about" onClick={this.closeNav} className="nav_link">About Us</Link>
                    <Link to="/brands" onClick={this.closeNav} className="nav_link">Our Brands</Link>
                    <Link to="/media" onClick={this.closeNav} className="nav_link">Media</Link>
                    <Link to="/contact" onClick={this.closeNav} className="nav_link">Contact Us</Link>
                </div>
                <div className={backShadeClasses.join(" ")} ></div>
                <nav className="c_navbar" id="c_brand">
                    <Link to="/" className="navbar_brand">
                        <img src={"/assets/images/logo_svg.svg"} alt="" className="" id="brand" />
                    </Link>
                    {leftNavHandlerBtn}
                    <div className="navbar_nav" id="navbar">
                        <Link to="/about" className="nav_link">About Us</Link>
                        <Link to="/brands" className="nav_link">Our Brands</Link>
                        <Link to="/media" className="nav_link">Media</Link>
                        <Link to="/contact" className="nav_link">Contact Us</Link>
                    </div>
                </nav>
                <Switch>
                    <Route path="/" exact render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Home />
                        </Suspense>
                    )} />

                    <Route path="/contact" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Contact />
                        </Suspense>
                    )} />
                    <Route path="/brands" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Brands />
                        </Suspense>
                    )} />
                    <Route path="/manufacturing_list" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ManufacturingUnit routeType={"ll"} />
                        </Suspense>
                    )} />
                    <Route path="/manufacturing-list" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ManufacturingUnit />
                        </Suspense>
                    )} />
                    <Route path="/media/Investmen-Round-led-by-ChrysCapital-and-Motilal-Oswal" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <PressRelease />
                        </Suspense>
                    )} />
                    <Route path="/media" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Media />
                        </Suspense>
                    )} />
                    <Route path="/about" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <About />
                        </Suspense>
                    )} />
                      <Route path="/privacy-policy" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <PrivacyPolicy />
                        </Suspense>
                    )} />
                    <Route path="/healthkart" render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Healthkart />
                        </Suspense>
                    )} />

                    <Route render={() => (
                        <Suspense fallback={<div>Loading...</div>}>
                            <NotFound />
                        </Suspense>
                    )} />
                </Switch>
            </React.Fragment>
        );
    }
}


export default Header;
